import "../css/app.css";

import { createSSRApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import animateIn from "@/Directives/AnimateIn.js";

createInertiaApp({
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob("./Pages/**/*.vue"),
    ),
  setup({ el, App, props, plugin }) {
    // App.directive('animate-in', AnimateIn)
    const ssrApp = createSSRApp({ render: () => h(App, props) }).use(plugin);
    ssrApp.directive("animate-in", animateIn);
    ssrApp.mount(el);
    return ssrApp;
  },
  progress: {
    delay: 250,
    color: "#66B3BD",
  },
});
